import Q from 'q';
import _ from 'underscore';

import ImageVerificationCode from '@/component/image-verification-code/index.vue';

import web_common_register from '@/lib/data-service/default/web_common_register';

export default {
  components: {
    ImageVerificationCode,
  },
  data() {
    return {
      ruleForm: {
        verification_code: ``,
      },
      rules: {
        verification_code: [
          { required: true, message: '请输入图片验证码！', trigger: 'blur' },
        ],
      },

      dialog: {
        visible: false,
        defer_init_completed: null,
      },

      btn_show_dialog: {
        enabled: true,
        text: `获取短信验证码`,
        default_text: `获取短信验证码`,
        seconds_max_disabled: 60,
        seconds_current_disabled: 0,
      },

    }
  },
  props: {
    verify_code_close: Function
  },
  watch: {
    'dialog.visible': function (val) {
      // this.__btn_submit_click()
    }
  },
  methods: {
    handleClose() {
      if (this.verify_code_close) {
        this.verify_code_close()
        this.dialog.visible = false;
      }
    },


    __get_phone() {
    },

    __el_dialog_open_event_handler() {
      const __this = this;
      //参考：https://www.jianshu.com/p/d90984d6cb3a
      __this.$nextTick(function () {
        __this.dialog.defer_init_completed.resolve();
      });
    },

    __get_el_dialog_open_completed_promise() {
      const __this = this;
      return __this.dialog.defer_init_completed.promise;
    },

    __btn_cancel_click() {
      if (this.verify_code_close) {
        this.verify_code_close()
      }
      const __this = this;
      __this.dialog.visible = false;
    },

    __btn_show_dialog_click() {
      const __this = this;

      if (!_.isFunction(__this.__get_phone)) {
        const msg = '__show parameter error, code=001';
        console.error(msg);
        return Q.reject({ msg: msg });
      }

      const phoneNumber = __this.__get_phone();
      if (!phoneNumber) {
        if (this.verify_code_close) {
          __this.verify_code_close()
        }
        alert(`请输入手机号码！`);
        return;
      }
      const reg = /^1[\d]{10}$/gi;
      if (!phoneNumber.match(reg)) {
        if (this.verify_code_close) {
          __this.verify_code_close()
        }
        alert(`手机号码格式不合法`);
        return;
      }

      __this.phoneNumber = phoneNumber;

      Q.when()
        .then(function () {
          __this.dialog.visible = true;
          __this.dialog.defer_init_completed = Q.defer();//defer需要在dialog每一次显示时进行一次初始化
          return __this.__get_el_dialog_open_completed_promise();
        })
        .then(function () {
          __this.$refs.ruleForm.resetFields();
          __this.$refs.txt_verification_code.$el.querySelector('input').focus();
        })
        .catch(function (ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        })
        ;

    },

    __btn_submit_click() {
      const __this = this;
      if (this.verify_code_close) {
        this.verify_code_close()
      }
      Q.when()
        .then(function () {
          const validate_form = function () {
            const defer = Q.defer();
            __this.$refs.ruleForm.validate((valid) => {
              if (!valid) {
                defer.reject();
                return;
              }
              defer.resolve();
            });
            return defer.promise;
          };
          return validate_form();
        })
        .then(function () {
          return __this.$refs.aImageVerificationCode.get_data();
        })
        .then(function (data) {
          const phoneNumber = __this.phoneNumber;
          const verificationCode = __this.ruleForm.verification_code;
          const roleType = 2;
          const uuid = data.uuid;

          return web_common_register({
            phoneNumber,
            verificationCode,
            roleType,
            uuid,
          });
        })
        .then(function () {
          __this.dialog.visible = false;
          if (__this.verify_code_close) __this.verify_code_close();
          __this.btn_show_dialog.enabled = false;
          __this.btn_show_dialog.seconds_current_disabled = __this.btn_show_dialog.seconds_max_disabled;
          __this.btn_show_dialog.text = `${__this.btn_show_dialog.text}(${__this.btn_show_dialog.seconds_current_disabled})`;

          const id = setInterval(function () {
            if (__this.btn_show_dialog.seconds_current_disabled <= 0) {
              clearInterval(id);
              __this.btn_show_dialog.enabled = true;
              __this.btn_show_dialog.text = __this.btn_show_dialog.default_text;
              return;
            }
            __this.btn_show_dialog.seconds_current_disabled -= 1;
            __this.btn_show_dialog.text = `${__this.btn_show_dialog.default_text}(${__this.btn_show_dialog.seconds_current_disabled})`;
          }, 1000);
        })
        .catch(function (ex) {
          // console.error(ex);
          __this.$refs.aImageVerificationCode.refresh_image_verification_code();
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        })
        ;
    },

    init(pParameter) {
      const __this = this;

      if (!pParameter) pParameter = {};
      const get_phone = pParameter.get_phone;
      if (!_.isFunction(get_phone)) {
        const msg = 'init parameter error, code=001';
        console.error(msg);
        return Q.reject({ msg: msg });
      }

      __this.__get_phone = get_phone;
    },

  },
  created() {
    const __this = this;
  },
}
