import Q from 'q';
import _ from 'underscore';
import web_common_kaptcha from '@/lib/data-service/default/web_common_kaptcha';

export default {
  data() {
    return {
      data_web_common_kaptcha: {
        img: ``,
        uuid: ``,
      },

      promise_init_completed: null,

    }
  },
  methods: {
    __init_web_common_kaptcha() {
      const __this = this;

      return Q.when()
        .then(function () {
          return web_common_kaptcha();
        })
        .then(function (data) {
          __this.data_web_common_kaptcha = data;
        })
        .catch(function (ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        })
        ;
    },

    __btn_image_click() {
      const __this = this;
      __this.refresh_image_verification_code();
    },

    get_data() {
      const __this = this;
      return Q.when()
        .then(function () {
          return __this.promise_init_completed;
        })
        .then(function () {
          return Q.resolve(__this.data_web_common_kaptcha);
        })
        .catch(function (ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        })
        ;

    },

    refresh_image_verification_code() {
      const __this = this;
      return Q.when()
        .then(function () {
          return __this.promise_init_completed;
        })
        .then(function () {
          return __this.__init_web_common_kaptcha();
        })
        .catch(function (ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        })
        ;
    },

  },
  created() {
    const __this = this;

    __this.promise_init_completed = Q.when()
      .then(function () {
        return __this.__init_web_common_kaptcha();
      })
  },
}
