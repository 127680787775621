// import extend from 'extend';
import Q from "q";
import _ from "underscore";
// import moment from 'moment';
// 修改密码
import web_common_user_updatePassword from "@/lib/data-service/default/web_common_user_updatePassword.js";
// 获取账号类型
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";
// 获取支付密码状态 1是已设置，其他是未设置
import wallet_account_queryAccountDetail from "@/lib/data-service/default/wallet_account_queryAccountDetail";
// 获取绑定的手机号码
import wallet_account_getAccountPhone from "@/lib/data-service/default/wallet_account_getAccountPhone";
// 提交验证码，手机
import web_common_phoneCodeCheck from "@/lib/data-service/default/web_common_phoneCodeCheck";
// 更新手机号码
import wallet_account_updateAccountPhone from "@/lib/data-service/default/wallet_account_updateAccountPhone";
// 支付密码
import wallet_account_updateUserPassword from "@/lib/data-service/default/wallet_account_updateUserPassword";

// 验证码弹窗
import ButtonGetSmsVerificationCode from "@/component/button-get-sms-verification-code/index.vue";
//设置支付密码
import setUserPassword from '@/lib/data-service/default/wallet_account_setUserPassword'
export default {
  components: {
    ButtonGetSmsVerificationCode,
  },
  data() {
    var validateNewPass = (rules, value, callback) => {
      if (value === "" || value === null || value === undefined) {
        callback(new Error("请输入新密码"));
      } else {
        const password = this.loginForm.oldPassword;
        if (value.length < 6) {
          callback(new Error("密码长度不能少于6位"));
        } else if (/\W/g.test(value) || !/\d/g.test(value) || !/[a-zA-Z]/g.test(value)) {
          callback(new Error('密码必须数字与字母组合'));
        } else if (value === password) {
          callback(new Error("不能与原密码相同"));
        } else if (
          /\W/g.test(value) ||
          !/\d/g.test(value) ||
          !/[a-zA-Z]/g.test(value)
        ) {
          callback(new Error("密码必须数字与字母组合"));
        } else {
          callback();
        }
      }
    };

    var validateCheckPass = (rules, value, callback) => {
      if (value === "" || value === null || value === undefined) {
        callback(new Error("请再次确认密码"));
      } else {
        const password = this.loginForm.newPassword;
        if (value.length < 6) {
          callback(new Error("密码长度不能少于6位"));
        } else if (value !== password) {
          callback(new Error("两次密码输入不一致"));
        } else {
          callback();
        }
      }
    };

    var validateNewPayment = (rules, value, callback) => {
      if (value === "" || value === null || value === undefined) {
        callback(new Error("请输入新密码"));
      } else {
        const password = this.paymentForm.oldPassword;
        if (!/\d{6}/.test(value)) {
          callback(new Error("密码格式不正确"));
        } else if (value === password) {
          callback(new Error("不能与原密码相同"));
        } else {
          callback();
        }
      }
    };

    var validateCheckPayment = (rules, value, callback) => {
      if (value === "" || value === null || value === undefined) {
        callback(new Error("请再次确认密码"));
      } else {
        const password = this.paymentForm.newPassword;
        if (!/\d{6}/.test(value)) {
          callback(new Error("密码格式不正确"));
        } else if (value !== password) {
          callback(new Error("两次密码不一致"));
        } else {
          callback();
        }
      }
    };

    return {
      passwordStatus: "",
      userType: 0,
      userVo: "",
      phone: "",
      show: false,
      loading: true,
      loginVisible: false,
      paymentVisible: false,
      showNewPayment: false,
      paymentTop: "30vh",
      phoneVisible: false,
      showNewPhone: false,
      loginForm: {
        oldPassword: "",
        newPassword: "",
        checkPass: "",
      },
      loginRules: {
        oldPassword: [
          { required: true, message: "请输入原始密码", trigger: "blur" },
          { min: 6, message: "密码长度不能少于6位", trigger: "blur" },
        ],
        newPassword: [
          { required: true, validator: validateNewPass, trigger: "blur" },
        ],
        checkPass: [
          { required: true, validator: validateCheckPass, trigger: "blur" },
        ],
      },

      paymentForm: {
        phone: "",
        smsCode: "",
        oldPassword: "",
        newPassword: "",
        checkPass: "",
      },
      paymentRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确格式的手机号码",
            trigger: "blur",
          },
        ],
        smsCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            pattern: /\d{4}/,
            message: "请输入正确格式的验证码",
            trigger: "blur",
          },
        ],
        oldPassword: [
          { required: true, message: "请输入原始密码", trigger: "blur" },
          {
            pattern: /\d{6}/,
            message: "请输入正确格式的密码",
            trigger: "blur",
          },
        ],
        newPassword: [
          { required: true, validator: validateNewPayment, trigger: "blur" },
        ],
        checkPass: [
          { required: true, validator: validateCheckPayment, trigger: "blur" },
        ],
      },

      phoneForm: {
        phone: "",
        smsCode: "",
      },
      phoneRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确格式的手机号码",
            trigger: "blur",
          },
        ],
        smsCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { pattern: /\d{4}/, message: "请输入正确格式的验证码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  methods: {
    init_account_safety() {
      const _this = this;

      Q.when()
        .then(function () {
          return wallet_account_queryAccountDetail();
        })
        .then(function (res) {
          _this.passwordStatus = res.passwordStatus || 2;
          _this.loading = false;
        });
    },
    req_phone() {
      const _this = this;
      Q.when()
        .then(function () {
          return wallet_account_getAccountPhone();
        })
        .then(function (res) {
          _this.phone = res.phone;
        });
    },
    req_change_login_password(params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_user_updatePassword(params);
        })
        .then(function (res) {
          _this.close_loginForm();
          _this.initialize();
          _this.$message.success("修改密码成功");
        });
    },
    req_code_phone(params) {
      return Q.when().then(function () {
        return web_common_phoneCodeCheck(params);
      });
    },
    req_change_payment_password(params) {
      const _this = this;
      Q.when()
        .then(function () {
          return wallet_account_updateUserPassword(params);
        })
        .then(function (res) {
          _this.close_paymentForm();
          _this.initialize();
          _this.$message.success("修改支付密码成功");
        });
    },
    _setUserPassword() {
      let data = {
        phone: this.paymentForm.phone,
        password: this.paymentForm.newPassword
      }
      setUserPassword(data).then(res => {
        console.log(res)
        this.close_paymentForm();
        this.initialize();
        this.$message.success("设置支付密码成功");
      })
    },
    req_update_phone(params) {
      const _this = this;
      Q.when()
        .then(function () {
          return wallet_account_updateAccountPhone(params);
        })
        .then(function (res) {
          _this.close_phoneForm();
          _this.initialize();
          _this.$message.success("修改手机成功");
        });
    },
    initialize() {
      this.loading = true;
      this.req_phone();
      get_user_info().then((res) => {
        this.userType = res.userVo.userType;
        if (this.userType == 7) {
          this.userVo = res.userVo;
        }
        this.init_account_safety();
      });
    },
    popup_code() {
      this.paymentTop = "-500vh";
    },
    payment_opened() {
      if (this.$refs.paymentCode) {
        const _this = this;
        this.$refs.paymentCode.init({
          get_phone() {
            return _this.paymentForm.phone;
          },
        });
      }
    },
    payment_phone_blur() {
      if (this.$refs.paymentCode) {
        const _this = this;
        this.$refs.paymentCode.init({
          get_phone() {
            return _this.paymentForm.phone;
          },
        });
      }
    },
    popup_login() {
      this.loading = true;
      const _this = this;
      this.loginVisible = true;
      setTimeout(() => {
        _this.loading = false;
      }, 200)
    },
    close_loginForm() {
      this.loading = true;
      this.loginVisible = false;
      const _this = this;
      setTimeout(() => {
        _this.loading = false;
      }, 200)
      this.$refs.loginForm.resetFields();
    },
    commit_loginForm() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const form = this.loginForm;
          this.req_change_login_password({
            oldPassword: form.oldPassword,
            newPassword: form.newPassword,
          });
        } else {
          return false;
        }
      });
    },
    popup_paymentForm() {
      this.paymentForm.phone = this.phone;
      this.paymentVisible = true;
    },
    close_paymentForm() {
      this.paymentVisible = false;
      this.$refs.paymentForm.resetFields();
      this.paymentForm.phone = '';
    },
    commit_paymentForm() {
      this.$refs.paymentForm.validate((valid) => {
        if (valid) {
          const _this = this;
          const form = _this.paymentForm;
          _this.req_code_phone({
            phone: form.phone,
            smsCode: form.smsCode,
          }).then(res => {
            this.passwordStatus === 2 ? this._setUserPassword() :
              this.req_change_payment_password({
                oldPassword: form.oldPassword,
                newPassword: form.newPassword,
              });
          })
        } else {
          return false;
        }
      });
    },

    popup_phoneForm() {
      this.phoneForm.phone = this.phone;
      if (!this.phone) {
        this.showNewPhone = true;
      }
      this.phoneVisible = true;
    },
    phone_blur() {
      const phone = this.phoneForm.phone;
      if (/^1[3456789]\d{9}$/.test(phone)) {
        const _this = this;
        if (_this.$refs.newPhoneCode) {
          _this.$refs.newPhoneCode.init({
            get_phone() {
              return phone;
            },
          });
        }
      }
    },
    phone_open() {
      const _this = this;
      if (this.$refs.phoneCode) {
        this.$refs.phoneCode.init({
          get_phone() {
            return _this.phoneForm.phone;
          },
        });
      }
    },
    verify_code_close() {
      this.paymentTop = "30vh";
    },
    close_phoneForm() {
      this.phoneVisible = false;
      this.$refs.phoneForm.resetFields();
      this.showNewPhone = false;
    },
    phoneForm_next() {
      const _this = this;
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          Q.when()
            .then(function () {
              return _this.req_code_phone(_this.phoneForm);
            })
            .then(function (res) {
              _this.showNewPhone = true;
            });
        } else {
          return false;
        }
      });
    },
    commit_phoneForm() {
      const _this = this;
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          Q.when()
            .then(function () {
              return _this.req_code_phone(_this.phoneForm);
            })
            .then(function (res) {
              _this.req_update_phone({ phone: _this.phoneForm.phone });
            });
        } else {
          return false;
        }
      });
    },
  },
  created() { },
  mounted() { },
  activated() {
    this.initialize();
  },
  watch: {
    showNewPhone(val) {
      this.phoneForm = {
        phone: "",
        smsCode: "",
      };
    },
  },
};
