const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：
// http://192.168.0.15:8080/doc.html#/所有接口/wallet-account-controller/queryAccountDetailUsingPOST
// http://192.168.0.15:9988/swagger-ui.html#/wallet-account-controller/queryAccountDetailUsingPOST
const web_common_phoneCodeCheck = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/web/common/phoneCodeCheck',
    data: pParameter
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_phoneCodeCheck;
